import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Div,
  FormItem,
  Group,
  ModalCard,
  ModalRoot,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { findObjectsDiffs } from 'lib/utils/findObjectsDiffs';
import { replaceEmptyWithNull } from 'lib/utils/replaceEmptyWithNull';
import { ADMIN_DIRECTORIES_ROUTE } from 'lib/constants';
import useFormItems from 'lib/hooks/useFormItems';
import useFetch from 'lib/hooks/useFetch';
import { eventTagBlank } from './constants.eventTagsDirectory';
import { IEventTag } from './types.eventTagsDirectory';
import PanelTitle from 'components/common/PanelTitle';
import EventFormItem from 'components/common/EventFormItem';

const EventTagsDirectoryEdit = () => {
  const { data, loading, fetchData, error } = useFetch<IEventTag>();
  const { fetchData: patchData } = useFetch();
  const [eventTagData, setEventTagData] = useState<IEventTag>(eventTagBlank);
  const { directoryId, methodType } = useParams();
  const navigate = useNavigate();
  const { setSnackbarContent } = useSnackbar();
  const [openModal, setOpenModal] = useState<string>('');
  const { formData, isError, errorItems, handleChange } =
    useFormItems<IEventTag>({
      initialValues: eventTagData,
    });
  const { name } = formData;
  const [isNewActive, setIsNewActive] = useState<boolean>(false);

  const handleDeleteTags = () => {
    fetchData({
      path: `/tag/tags/${directoryId}/`,
      method: 'delete',
      onSuccessMessage: 'Успешно удалено',
    })
      .then(() => setOpenModal(''))
      .then(() => navigate(`${ADMIN_DIRECTORIES_ROUTE}/event_tags`));
  };

  const handleSave = async () => {
    if (methodType === 'addNew') {
      // eslint-disable-next-line
      const { id, ...diffData } = formData;
      diffData.is_active = isNewActive;

      const addPromise = patchData({
        path: `/tag/tags/`,
        method: 'post',
        body: {
          ...diffData,
          level: 0,
        },
        onSuccessMessage: 'Успешно добавлено',
      });

      const response = await addPromise;
      const error = response?.errorData?.response?.data?.detail;

      if (error?.code) {
        setSnackbarContent({
          type: 'error',
          message: error.error_message,
        });
      } else {
        await navigate(`${ADMIN_DIRECTORIES_ROUTE}/event_tags`);
      }
    }

    if (methodType === 'edit') {
      const diffObject = findObjectsDiffs(formData, eventTagData);
      const diffKeys = Object.keys(diffObject);
      // eslint-disable-next-line
      const diffData: Record<string, any> = {};

      if (diffKeys.length !== 0) {
        diffKeys.forEach((key) => {
          diffData[key] = formData[key as keyof IEventTag];
        });
      }

      if (eventTagData?.is_active !== isNewActive)
        diffData.is_active = isNewActive;

      if (Object.keys(diffData).length > 0) {
        const editPromise = patchData({
          path: `/tag/tags/${directoryId}/`,
          method: 'patch',
          body: replaceEmptyWithNull(diffData),
          onSuccessMessage: 'Изменения сохранены',
        });

        const response = await editPromise;
        const error = response?.errorData?.response?.data?.detail;

        if (error?.code) {
          setSnackbarContent({
            type: 'error',
            message: error.error_message,
          });
        } else {
          await fetchDataFunction();
        }
      }
    }
  };

  const handleIsNewActive = (event: ChangeEvent<HTMLInputElement>) => {
    setIsNewActive(event.target.checked);
  };

  const fetchDataFunction = () => {
    if (methodType === 'edit') {
      fetchData({ path: `/tag/tags/${directoryId}/`, method: 'get' });
    }
  };

  useEffect(() => {
    fetchDataFunction();
  }, []);

  useEffect(() => {
    if (data && !loading) {
      setEventTagData(data);
      setIsNewActive(data.is_active);
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <PanelTitle>
            <Title className="text-color-black">
              {methodType === 'edit' ? `Teг` : 'Добавить тег для мероприятия'}
            </Title>
            {methodType === 'edit' && (
              <Title className="inline text-color-steel-gray-500">
                {eventTagData?.name}
              </Title>
            )}
          </PanelTitle>
          <Group className="custom-scrollbar">
            <EventFormItem
              className="vkui-input"
              top="Название"
              name="name"
              value={name}
              onChange={handleChange}
              placeholder="Введите название тега"
              isRequired={true}
              isError={isError}
              errorItems={errorItems}
              error={error}
            />
            <FormItem className="py-0 mt-3 mb-2 ml-3 w-32">
              <Checkbox checked={isNewActive} onChange={handleIsNewActive}>
                Активно
              </Checkbox>
            </FormItem>
            <Div>
              <ButtonGroup align="right" stretched>
                {methodType === 'edit' ? (
                  <>
                    <Button
                      className="vkui-edit-button-secondary"
                      mode="secondary"
                      size="m"
                      appearance="accent"
                      onClick={() => setOpenModal('delete')}
                    >
                      Удалить
                    </Button>
                    <Button
                      className="vkui-edit-button-primary"
                      mode="primary"
                      size="m"
                      appearance="accent"
                      onClick={handleSave}
                    >
                      Сохранить
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => navigate(-1)}
                      className="vkui-edit-button-secondary"
                      mode="secondary"
                      size="m"
                      appearance="accent"
                    >
                      Назад
                    </Button>
                    <Button
                      className="vkui-edit-button-primary"
                      mode="primary"
                      size="m"
                      appearance="accent"
                      onClick={handleSave}
                    >
                      Добавить
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Div>
          </Group>
        </>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalCard
          id="delete"
          onClose={() => setOpenModal('')}
          className="fixed"
        >
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Удаление тега
            </Title>
            <Text className="text-color-gray-600">Данный тег будет удален</Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => handleDeleteTags()}
              >
                Удалить
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
      </ModalRoot>
    </>
  );
};

export default EventTagsDirectoryEdit;
