import { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import apiService from 'lib/api/api';
import { useSnackbar } from './useSnackbar';
import { IResponseError, IResponseFieldError } from 'lib/types';
import { parseErrorMessage } from 'lib/utils/parseErrorMessage';
import { useAuth } from 'providers/AuthProvider';
import { authService } from 'lib/api/authService';
import { getUserToken } from 'lib/utils/authUtils';

const useFetch = <T>() => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<AxiosError<IResponseError> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { setSnackbarContent } = useSnackbar();
  const { setUser } = useAuth();

  const handleRefreshToken = async () => {
    const refreshToken = getUserToken('refresh');

    if (refreshToken) {
      try {
        const apiResponse = await authService.refreshToken(`/auth/refresh`, {
          refresh_token: refreshToken,
        });
        const userData = apiResponse.data;
        localStorage.setItem('user', JSON.stringify(userData));
        setUser(userData);
      } catch (error) {
        setSnackbarContent({
          type: 'error',
          message: 'Ошибка при авторизации',
        });
        localStorage.removeItem('user');
        setUser(null);
      }
    }
  };

  const fetchData = async ({
    path,
    method,
    body,
    onSuccessMessage,
    onErrorMessage = 'Что-то пошло не так',
  }: {
    path: string;
    method: 'get' | 'post' | 'put' | 'patch' | 'delete';
    body?: T;
    onSuccessMessage?: string;
    onErrorMessage?: string;
  }) => {
    try {
      setLoading(true);

      let response: AxiosResponse<T>;

      switch (method) {
        case 'post':
          response = await apiService.post<T>(path, body);
          break;
        case 'put':
          response = await apiService.put<T>(path, body);
          break;
        case 'patch':
          response = await apiService.patch<T>(path, body);
          break;
        case 'delete':
          body
            ? (response = await apiService.deleteAll<T>(path, body))
            : (response = await apiService.delete<T>(path));
          break;
        default:
          response = await apiService.get<T>(path);
      }

      setData(response.data);
      setError(null);

      onSuccessMessage &&
        setSnackbarContent({
          type: 'success',
          message: onSuccessMessage,
        });
    } catch (error) {
      const typedError = error as AxiosError<IResponseError>;

      if (
        typedError.response?.data.error_message ===
          'Signature verification failed' ||
        typedError.response?.data.error_message === 'Signature has expired'
      ) {
        handleRefreshToken();
      }

      if (typedError.code === 'ERR_NETWORK') {
        setError(typedError);

        return setSnackbarContent({
          type: 'error',
          message: onErrorMessage,
        });
      }

      const errorResponse = typedError.response?.data;

      const parsedErrorResponse =
        errorResponse?.field_errors && errorResponse?.field_errors.length > 0
          ? errorResponse
          : parseErrorMessage(typedError);

      const parsedErrorFields =
        parsedErrorResponse?.field_errors?.map((item: IResponseFieldError) => {
          const match = item.message.match(/<([^>]*)>/);
          const fieldName = match ? match[1] : '';

          return {
            field: item.field,
            message: item.message.includes('already exists')
              ? `${fieldName} уже существует`
              : item.message,
          };
        }) || [];

      setError({
        ...typedError,
        response: {
          data: {
            ...typedError.response?.data,
            parsedErrorFields,
          },
          // eslint-disable-next-line
        } as any,
      });

      setSnackbarContent({
        type: 'error',
        message:
          parsedErrorResponse.error_message === 'Unique name violated'
            ? onErrorMessage
            : parsedErrorResponse.error_message,
      });

      return {
        errorData: typedError,
        errorResponse: parsedErrorResponse,
      };
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, fetchData };
};

export default useFetch;
