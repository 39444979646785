export const rankingHeaders = [
  {
    order: 1,
    label: 'Тип мероприятия',
    value: 'category',
  },
  {
    order: 2,
    label: 'Город',
    value: 'cities_obj',
  },
  {
    order: 3,
    label: 'Статус',
    value: 'is_active',
  },
  {
    order: 4,
    label: 'Кол-во событий',
    value: 'events_count',
  },
  {
    order: 5,
    label: 'Дата создания',
    value: 'created_at',
  },
];

export const rankingFilters = {
  ordering: '',
  q: '',
  name: [],
  category_id: [],
  cities: [],
  is_active: '',
};

export const rankingBlank = {
  id: '',
  created_at: '',
  updated_at: '',
  is_active: true,
  category_id: '',
  cities: [],
  events: [],
  category: {
    id: '',
    name: '',
  },
  events_obj: [],
  cities_obj: [],
};
