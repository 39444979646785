export const userHeaders = [
  {
    order: 1,
    label: 'ФИО',
    value: 'name',
  },
  {
    order: 2,
    label: 'Почта',
    value: 'email',
  },
  {
    order: 3,
    label: 'Статус',
    value: 'status',
  },
  {
    order: 4,
    label: 'Роль',
    value: 'role',
  },
];

export const userFilters = {
  q: '',
  status: '',
  role: '',
};

export const userBlank = {
  id: '',
  firstname: '',
  lastname: '',
  middlename: '',
  email: '',
  phone: '',
  status: '',
  role: '',
  password: '',
};
