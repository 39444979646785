// eslint-disable-next-line
export const parseErrorMessage = (error: any) => {
  const errorMessage = error.response?.data?.error_message;

  if (!errorMessage) {
    return {};
  }

  try {
    const parseObj = JSON.parse(
      errorMessage.replace(/'/g, '"').replace(/None/g, '[]'),
    );

    return parseObj;
  } catch (parseError) {
    console.error('Ошибка разбора JSON:', parseError);
    return {};
  }
};
