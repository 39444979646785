export const getClearUrl = (
  obj: string,
  type: string,
  slug: string,
  url: 'web' | 'miniapp',
): string | undefined => {
  if (url === 'web') {
    let webUrl = process.env.REACT_APP_SLUG_BASE_URL;

    if (obj === 'event') {
      webUrl += '/moskva';
      type === 'Кино' ? (webUrl += '/movie/') : (webUrl += '/event/');
    }

    if (obj === 'place') {
      webUrl += '/moskva';
      type === 'Кинотеатр' ? (webUrl += '/cinema/') : (webUrl += '/place/');
    }

    if (obj === 'person') {
      webUrl += '/person/';
    }

    return webUrl + slug;
  }

  if (url === 'miniapp') {
    let miniAppUrl = process.env.REACT_APP_MINIAPP_BASE_URL;

    if (obj === 'event') {
      miniAppUrl +=
        type === 'Кино'
          ? `/poster/movie-detail/${slug}`
          : `path=/poster/activity-detail/${slug}`;
    }

    if (obj === 'place') {
      miniAppUrl +=
        type === 'Кинотеатр'
          ? `/poster/cinema-detail/${slug}`
          : `path=/poster/venue-activities-list/${slug}`;
    }

    return miniAppUrl;
  }

  return undefined;
};
