import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '@vkontakte/vkui';
import Layout from 'layout';
import PanelTitle from 'components/common/PanelTitle';
import EventsEdit from 'components/Events/EventsEdit';
import Events from 'components/Events/Events';

const EventsPage: FC = () => {
  const { methodType } = useParams();

  return (
    <Layout>
      {!methodType ? (
        <PanelTitle withArrow={false}>
          <Title className="text-color-black">Мероприятия</Title>
        </PanelTitle>
      ) : null}
      {methodType ? <EventsEdit /> : <Events />}
    </Layout>
  );
};

export default EventsPage;
