export const typeHeaders = [
  {
    order: 1,
    label: 'Тип',
    value: 'name',
  },
  {
    order: 2,
    label: 'Активность',
    value: 'is_active',
  },
  {
    order: 3,
    label: 'Приоритет',
    value: 'priority',
  },
  {
    order: 4,
    label: 'Варианты наименований',
    value: 'variant_names',
  },
  {
    order: 5,
    label: 'Псевдоним',
    value: 'alias',
  },
];

export const typeFilters = {
  q: '',
  name: '',
  is_active: '',
};

export const typeBlank = {
  id: '',
  name: '',
  is_active: false,
  alias: '',
};